'use client';
import { useRef } from 'react';
import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';
import Image from 'next/image';
import { mobileNavBarLinks } from './data/navigation-links';

type ComponentProps = {
	mobileMenuOpen: boolean;
	setMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function MobileMenu({ mobileMenuOpen, setMobileMenuOpen }: ComponentProps) {
	const closeButtonRef = useRef<HTMLDivElement>(null);
	const closePanel = () => {
		closeButtonRef.current?.click();
	};
	return (
		<Dialog as="div" className="lg:hidden" onClose={setMobileMenuOpen} open={mobileMenuOpen}>
			<div className="fixed inset-0 z-50" />
			<Dialog.Panel className="fixed inset-y-0 right-0 z-50 flex w-full flex-col overflow-x-hidden overflow-y-scroll bg-gray-800 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 md:max-w-full">
				<div className="flex items-center justify-between px-4 py-4 md:px-6">
					<div className="flex items-center divide-gray-100 p-1.5 lg:flex-1">
						<Link href="https://www.utahcounty.gov/">
							<span className="sr-only">Your Company</span>
							<div className="flex h-9 max-h-9 w-auto flex-shrink">
								<Image
									alt="logo icon"
									height="35"
									priority
									src="/Utah County Logo Blue Mtn White Text.svg"
									width="105"
								/>
							</div>
						</Link>
						<div className="mx-3 h-6 w-[1px] bg-white" />
						<Link className="flex" href="/home" onClick={closePanel}>
							<div className="group relative grid place-content-center">
								<div className="pt-1 font-serif text-2xl leading-5 text-white lg:mb-1">Elections Division</div>
							</div>
						</Link>
					</div>
					<button
						className="-m-2.5 rounded-md p-2.5 text-gray-700"
						onClick={(e) => {
							e.preventDefault();
							setMobileMenuOpen(false);
						}}
						type="button"
					>
						<span className="sr-only">Close menu</span>
						<div ref={closeButtonRef}>
							<XMarkIcon aria-hidden="true" className="h-10 w-10 text-white" />
						</div>
					</button>
				</div>
				<hr className="mt-1 h-[1px] w-full bg-black " />
				<div className="px-6">
					<div className=" divide-y divide-gray-500/10">
						<div>
							{mobileNavBarLinks.map((item) => {
								if (item.children) {
									return (
										<div className="my-5 text-2xl font-medium text-white" key={item.name}>
											<div>{item.name}</div>
											<div className="mt-2 flex divide-x-2">
												<div className="ml-5 h-full" />
												<div>
													{item.children.map((child) => (
														<div key={child.name}>
															<Link href={child.href !== undefined ? child.href : ''} onClick={closePanel}>
																<div className=" my-4 ml-3 text-2xl font-light text-gray-400">{child.name}</div>
															</Link>
														</div>
													))}
												</div>
											</div>
										</div>
									);
								}
								return (
									<Link href={item.href !== undefined ? item.href : ''} key={item.name} onClick={closePanel}>
										<div className="my-5 text-2xl font-medium text-white">{item.name}</div>
									</Link>
								);
							})}
						</div>
					</div>

					<Link className="my-5 flex items-center" href="/calendar" onClick={closePanel}>
						<div className="mr-2 flex h-10 max-h-10 w-10 max-w-[40px] flex-shrink">
							<Image alt="calendar icon" height="40" src="/vectors/calendarIcon.svg" width="40" />
						</div>
						<div className="text-2xl font-medium text-white">Calendar</div>
					</Link>

					<Link className="my-5 flex items-center" href="/voting-locations" onClick={closePanel}>
						<div className="mr-2 flex h-10 max-h-10 w-10 max-w-[40px] flex-shrink">
							<Image
								alt="map pin icon"
								height="40"
								src="/vectors/voting-box.svg"
								style={{ width: 'auto', height: 'auto' }}
								width="40"
							/>
						</div>
						<div className="text-2xl font-medium text-white">Voting Locations</div>
					</Link>
				</div>
				<div className="flex grow flex-col space-y-3 px-6 font-light text-white">
					<div className="space-y-3">
						<div className="mr-5 flex hover:cursor-pointer">
							<a className="flex items-center" href="tel:+18018518128">
								<div className="mr-4 h-10 max-h-10 w-10 max-w-[40px]">
									<Image alt="phone icon" height="40" src="/vectors/phoneIcon.svg" width="40" />
								</div>
								<span className="whitespace-nowrap text-xl text-white">801-851-8128</span>
							</a>
						</div>
						<div className="mr-5 flex hover:cursor-pointer">
							<a className="flex items-center" href="mailto:elections@utahcounty.gov">
								<div className="mr-4 h-10 max-h-10 w-10 max-w-[40px]">
									<Image alt="email icon" height="40" src="/vectors/emailIcon.svg" width="40" />
								</div>
								<span className="whitespace-nowrap text-xl text-white">elections@utahcounty.gov</span>
							</a>
						</div>
						<div className="flex hover:cursor-pointer">
							<a className="flex items-center" href="https://www.facebook.com/UtahCoElections/">
								<div className="mr-4 h-10 max-h-10 w-10 max-w-[40px]">
									<Image alt="facebook icon" height="40" src="/vectors/facebookIconWhite.svg" width="40" />
								</div>
								<span className="whitespace-nowrap text-xl text-white">Our Facebook Page</span>
							</a>
						</div>
					</div>
					<div className="mb-2 mt-5 font-light text-white">An Official Website of Utah County Government</div>
				</div>
			</Dialog.Panel>
		</Dialog>
	);
}
