'use client';
import Link from 'next/link';
import React, { useState, useEffect } from 'react';
import { getCookie, hasCookie, setCookie } from '../../_utils/cookie-jar';
import { AnnouncementBannerType } from '../../../types/announcement-banner';

type ComponentProps = {
	data?: AnnouncementBannerType;
};

export default function AnnouncementBanner({ data }: ComponentProps) {
	const [showBanner, setShowBanner] = useState(false);
	const bannerCookie = 'bannerActive';
	useEffect(() => {
		if (data?.show === false) {
			setShowBanner(false);
			return;
		}
		const checkBannerCookie = () => {
			if (hasCookie(bannerCookie)) {
				const cookie = getCookie(bannerCookie);
				if (cookie) {
					const cookieDate = new Date(cookie.timeCookieWasSet);
					const updatedAt = new Date(data?.updatedAt ?? 0);

					if (data?.updatedAt && cookieDate < updatedAt) {
						// Banner has been updated since the cookie was set so show it
						setShowBanner(true);
						setCookie(bannerCookie, 'true', 24, data.text);
					} else {
						setShowBanner(cookie.value === 'true');
					}
				}
			} else {
				setShowBanner(true);
				setCookie(bannerCookie, 'true', 24, data?.text ?? '');
			}
		};

		checkBannerCookie();
	}, [data?.updatedAt, data?.text, data?.show]);

	const handleNoticeClick = () => {
		setShowBanner(false);
		setCookie(bannerCookie, 'false', 24, data?.text ?? '');
	};

	const isExternalUrl = (url: string) => /^(http|https):\/\//.test(url);
	const ensureHttpUrl = (url: string) => {
		if (!isExternalUrl(url)) {
			return `http://${url}`;
		}
		return url;
	};

	if (!showBanner || !data) {
		return null; // Do not render anything if there is no data or if the banner shouldn't be shown
	}

	return (
		<div className="flex w-full items-center justify-between bg-red-900 py-2 text-center text-base text-white lg:text-sm">
			<div className="mx-auto flex w-full items-center justify-center">
				<div className="">{data.text}</div>
				{data.button?.url !== undefined && (
					<Link
						href={ensureHttpUrl(data.button.url)}
						rel={isExternalUrl(data.button.url) ? 'noopener noreferrer' : undefined}
						target={data.button.openInNewTab ? '_blank' : ''}
					>
						<button
							className="ml-5 rounded-lg bg-red-700 p-1 transition-all duration-300 ease-in-out hover:bg-red-500"
							type="button"
						>
							{data.button.text}
						</button>
					</Link>
				)}
			</div>
			<span
				className="py-1 pr-2 text-4xl hover:cursor-pointer md:pr-5 md:text-3xl lg:pr-10 xl:text-base"
				onClick={handleNoticeClick}
				style={{ userSelect: 'none' }}
			>
				&#10005; {/* Unicode for the "X" character */}
			</span>
		</div>
	);
}
