import Link from 'next/link';

interface CopyrightProps {
	links: { name: string }[];
}

export default function Copyright({ links }: CopyrightProps) {
	const currentYear = new Date().getFullYear();
	return (
		<div className="bg-UC-blue-900 border-t border-white/10 px-2 py-4">
			<div className="mx-auto flex max-w-7xl flex-wrap justify-start border-gray-500 text-center sm:flex-nowrap lg:divide-x">
				{links.map(({ name }) => {
					return (
						<Link
							className="mr-5 px-1 text-sm text-gray-300 hover:text-white sm:flex-initial lg:mr-0 lg:px-4 lg:text-xs"
							href="https://www.utahcounty.gov/disclaimer.html"
							key={name}
							rel="noopener"
							target="_blank"
						>
							{name}
						</Link>
					);
				})}
				<p className="border-fix flex-full px-2 pt-4 text-sm text-gray-300 sm:flex-initial sm:py-0 lg:text-xs">
					&copy; {currentYear} All Rights Reserved by Utah County Government
				</p>
			</div>
		</div>
	);
}
