import Image from 'next/image';

type ComponentProps = {
	color: string;
};

export default function GlobalHeader({ color }: ComponentProps) {
	let backgroundColor;
	switch (color) {
		case 'localhost-red':
			backgroundColor = 'bg-red-800';
			break;
		case 'staging-orange':
			backgroundColor = 'bg-orange-800';
			break;
		case 'production-blue':
			backgroundColor = 'bg-gray-800';
	}
	return (
		<div className={`isolate z-50 hidden h-8 w-full ${backgroundColor} text-white lg:block`}>
			<div className="mx-auto flex h-full max-w-7xl items-center justify-between p-2 lg:px-10 xl:px-2">
				<div className="text-xs font-light">An Official Website of Utah County Government</div>
				<div className="flex h-full w-1/3 justify-end space-x-3 text-xs font-light lg:w-2/5 xl:w-1/3">
					<div className="flex justify-around hover:cursor-pointer">
						<a className="flex items-center" href="tel:+18018518128">
							<div className="mr-1 flex h-4 max-h-4 w-4 max-w-[16px] flex-shrink">
								<Image alt="phone icon" height="16" src="/vectors/phoneIcon.svg" width="16" />
							</div>
							<span>(801) 851-8128</span>
						</a>
					</div>
					<div className="mx-0 h-full w-[1px] bg-white" />
					<div className="flex hover:cursor-pointer">
						<a className="flex items-center" href="mailto:elections@utahcounty.gov">
							<div className="mr-1 h-4 max-h-4 w-4 max-w-[16px]">
								<Image alt="email icon" height="16" src="/vectors/emailIcon.svg" width="16" />
							</div>
							<span>elections@utahcounty.gov</span>
						</a>
					</div>
					<div className="mx-0 h-full w-[1px] bg-white" />
					<div className="flex hover:cursor-pointer">
						<a className="flex items-center" href="https://www.facebook.com/UtahCoElections/">
							<div className="mr-1 h-5 max-h-5 w-5 max-w-[20px]">
								<Image alt="facebook icon" height="20" src="/vectors/facebookIconWhite.svg" width="20" />
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}
