import Link from 'next/link';

interface LinkGroupProps {
	links: { name: string; href: string }[];
	justify?: 'left' | 'right';
}

export default function LinkGroup({ links, justify }: LinkGroupProps) {
	return (
		<div className="mb-10 md:mx-3 lg:mx-0">
			<div className="space-y-5 md:space-y-3 lg:space-y-1">
				{links.map((item) => (
					<span className={`flex justify-${justify}`} key={item.name}>
						<Link
							className="md:font-regular whitespace-nowrap text-xl font-medium leading-6 text-gray-700 hover:text-gray-500 md:whitespace-normal md:text-base lg:text-base"
							href={item.href}
						>
							{item.name}
						</Link>
					</span>
				))}
			</div>
		</div>
	);
}
