import Link from 'next/link';

export default function ContactInfoSection() {
	return (
		<div className="mb-10 flex flex-col space-y-2 px-3 pl-4 lg:mb-0 lg:items-center lg:px-0 lg:pl-0">
			{/* <div className="text-lg font-semibold text-gray-700">Contact us</div> */}
			<div className="space-y-5 text-gray-700">
				<div>
					<div className="text-xl font-semibold text-black md:text-base lg:text-sm">Elections Office</div>
					<div className="text-xl text-gray-500 md:text-base lg:text-sm">100 E Center St # 3100, Provo, UT 84606</div>
				</div>
				<div>
					<div className="text-xl font-semibold text-black md:text-base lg:text-sm">8 AM - 5 PM</div>
					<a className="flex items-center" href="mailto:elections@utahcounty.gov">
						<div className="text-xl text-gray-500 underline hover:cursor-pointer md:text-base lg:text-sm">
							elections@utahcounty.gov
						</div>
					</a>
					<a className="flex items-center" href="tel:+18018518128">
						<div className="text-xl text-gray-500 hover:cursor-pointer md:text-base lg:text-sm">(801) 851-8128</div>
					</a>
				</div>

				<div className="text-md lg:text-sm">
					Office may close during{' '}
					<Link className="text-UC-blue-600 underline" href="https://www.utahcounty.gov/county-holidays">
						county holidays
					</Link>
				</div>
			</div>
		</div>
	);
}
