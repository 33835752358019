export interface NavBarLinks {
	name: string;
	href?: string;
	hasDropdown?: boolean;
	children?: NavBarLinks[];
}

export const navBarLinks: NavBarLinks[] = [
	{
		name: 'Results',
		href: '/results',
	},
	{
		name: 'Voters',
		href: '/voter-information',
	},
	{
		name: 'Candidates',
		hasDropdown: true,
		children: [
			{
				name: 'Information For Candidates',
				href: '/candidate-information',
			},
			{
				name: 'Candidate Records',
				href: '/candidate-records',
			},
			{
				name: 'Financial Disclosures',
				href: '/financial-disclosures',
			},
			{
				name: 'Conflict Of Interest Forms',
				href: '/conflict-of-interest-forms',
			},
		],
	},
	{
		name: 'Resources',
		hasDropdown: true,
		children: [
			{
				name: 'About Utah County Elections',
				href: '/about',
			},
			{
				name: 'Forms',
				href: '/forms',
			},
			{
				name: 'Maps',
				href: '/maps',
			},
			{
				name: 'Notices',
				href: '/public-notices',
			},
			{
				name: 'Petitions',
				href: '/petitions',
			},
			{
				name: 'Volunteer',
				href: '/volunteer',
			},
			{
				name: 'Calendar',
				href: '/calendar',
			},
			{
				name: 'Voting & Drop Box Locations',
				href: '/voting-locations',
			},
			{
				name: 'Ballot Center Tours',
				href: '/ballot-center-tours',
			},
			{
				name: 'Voter Information Pamphlets',
				href: '/voter-information#voter-information-pamphlets',
			},
		],
	},
];

export const mobileNavBarLinks: NavBarLinks[] = [
	{
		name: 'Results',
		href: '/results',
	},
	{
		name: 'Voters',
		href: '/voter-information',
	},
	{
		name: 'Candidates',
		children: [
			{
				name: 'Information For Candidates',
				href: '/candidate-information',
			},
			{
				name: 'Candidate Records',
				href: '/candidate-records',
			},
			{
				name: 'Financial Disclosures',
				href: '/financial-disclosures',
			},
		],
	},
	{
		name: 'Resources',
		children: [
			{
				name: 'About Utah County Elections',
				href: '/about',
			},
			{
				name: 'Forms',
				href: '/forms',
			},
			{
				name: 'Maps',
				href: '/maps',
			},
			{
				name: 'Notices',
				href: '/public-notices',
			},
			{
				name: 'Petitions',
				href: '/petitions',
			},
			{
				name: 'Volunteer',
				href: '/volunteer',
			},
			{
				name: 'Ballot Center Tours',
				href: '/ballot-center-tours',
			},
		],
	},
];
