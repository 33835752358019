'use client';
import { useState } from 'react';
import { Bars3Icon } from '@heroicons/react/24/outline';
import Link from 'next/link';
import Image from 'next/image';
import { navBarLinks } from './data/navigation-links';
import DropdownComponent from './dropdown-component';
import GlobalHeader from './global-header';
import MobileMenu from './mobile-menu';

type ComponentProps = {
	color: string;
};

export default function Header({ color }: ComponentProps) {
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	return (
		<div className="sticky top-0 z-50">
			<GlobalHeader color={color} />
			<header className="isolate z-50 h-20 w-full bg-gray-800/90 backdrop-blur-sm lg:top-8">
				<nav
					aria-label="Global"
					className="mx-auto flex max-w-7xl items-center justify-between px-4 py-4  md:px-6 lg:px-10 xl:px-0"
				>
					<div className="flex">
						<div className="flex items-center divide-gray-100 p-1.5 lg:flex-1">
							<Link href="https://www.utahcounty.gov/">
								<div className="flex h-9 max-h-9 w-auto flex-shrink">
									<Image alt="logo icon" height="35" src="/Utah County Logo Blue Mtn White Text.svg" width="105" />
								</div>
							</Link>
							<div className="mx-3 h-6 w-[1px] bg-white" />
							<Link href="/home">
								<div className="group relative grid place-content-center">
									<div className="pt-1 font-serif text-2xl leading-5 text-white lg:mb-1">Elections Division</div>
									<hr className="border-UC-yellow-500 absolute bottom-0 hidden w-full scale-x-0 border-b-[1.5px] transition duration-500 group-hover:scale-x-100 lg:block" />
								</div>
							</Link>
						</div>
					</div>
					<div className="flex lg:hidden">
						<button
							className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
							onClick={(e) => {
								e.preventDefault();
								setMobileMenuOpen(true);
							}}
							type="button"
						>
							<span className="sr-only">Open main menu</span>
							<Bars3Icon aria-hidden="true" className="h-10 w-10 text-white" />
						</button>
					</div>
					<div className="hidden items-center pr-2 lg:flex lg:flex-1 lg:gap-x-6">
						<div className="w-full lg:flex lg:justify-end lg:gap-x-8">
							{navBarLinks.map((item) => {
								if (item.children) {
									return <DropdownComponent item={item} key={item.name} />;
								}
								return (
									<Link href={item.href !== undefined ? item.href : '/home'} key={item.name}>
										<div className="group relative grid place-content-center text-sm font-semibold leading-6 text-white ">
											<p>{item.name}</p>
											<hr className="border-UC-yellow-500 absolute bottom-0 w-full scale-x-0 border-b-[1.5px] transition duration-500 group-hover:scale-x-100" />
										</div>
									</Link>
								);
							})}
						</div>
						<Link className="h-7 w-7 text-white hover:cursor-pointer" href="/calendar">
							<div className="mr-2 flex h-7 max-h-7 w-7 max-w-[28px] flex-shrink">
								<Image alt="calendar icon" height="28" src="/vectors/calendarIcon.svg" width="28" />
							</div>
						</Link>
						<Link className="h-7 w-7  text-white hover:cursor-pointer " href="/voting-locations">
							<div className="mr-2 flex h-7 max-h-7 w-7 max-w-[28px] flex-shrink pb-0.5 ">
								<Image
									alt="Ballot Box Icon"
									height="28"
									src="/vectors/voting-box.svg"
									style={{ width: 'auto', height: 'auto' }}
									width="28"
								/>
							</div>
						</Link>
					</div>
				</nav>
				<MobileMenu mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} />
			</header>
		</div>
	);
}
