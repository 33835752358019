interface CookieValue {
	value: string;
	timeCookieWasSet: string;
	announcementText: string;
}

// Function to get a cookie by name
export function getCookie(name: string): CookieValue | null {
	let cookieArray = document.cookie.split('; ');
	let cookie = cookieArray.find((row) => row.startsWith(`${name}=`))?.split('=')[1];
	if (cookie) {
		try {
			return JSON.parse(decodeURIComponent(cookie)) as CookieValue;
		} catch (e) {
			console.error('Failed to parse cookie:', e);
			return null;
		}
	}
	return null;
}

// Function to check if a cookie exists returns a bool
export function hasCookie(name: string): boolean {
	let cookie = getCookie(name);
	return cookie !== null;
}

export function setCookie(name: string, value: string, hours: number, announcementText: string) {
	let expires = '';
	if (hours) {
		let date = new Date();
		date.setTime(date.getTime() + hours * 60 * 60 * 1000);
		expires = `; expires=${date.toUTCString()}`;
	}
	const cookieValue: CookieValue = {
		value,
		timeCookieWasSet: new Date().toISOString(),
		announcementText,
	};
	document.cookie = `${name}=${encodeURIComponent(JSON.stringify(cookieValue))}${expires}; path=/`;
}
