'use client';
import Image from 'next/image';
import Link from 'next/link';
import Copyright from './components/copyright';
import LinkGroup from './components/link-group';
import { VotersAndCandidates, ResourcesColumn1, ResourcesColumn2 } from './data/navigation-links';
import ContactInfoSection from './components/contact-info';

export default function Footer() {
	const copyright = [{ name: 'Terms of Use' }, { name: 'Privacy Policy' }];
	return (
		<footer aria-labelledby="footer-heading" className="relative mt-10" id="layout-footer">
			<div className="bg-UC-bg-gray absolute inset-0" />
			<h2 className="sr-only" id="footer-heading">
				Footer
			</h2>
			<div className="relative max-w-full">
				<section className="bg-gray-50 p-8 md:px-4 md:py-8 lg:px-10 xl:px-0">
					<div className="mx-auto flex max-w-7xl flex-col items-center gap-7 md:flex-row ">
						<div className="font-serif text-3xl md:text-2xl">Utah County Elections</div>
						<div className="ml-5">
							<Link href="https://www.facebook.com/UtahCoElections/" target="_blank">
								<div className="h-7 max-h-7 w-auto flex-shrink">
									<Image alt="phone icon" height="28" src="/vectors/facebookIconGray.svg" width="28" />
								</div>
							</Link>
						</div>
					</div>
				</section>
				<section className="bg-gray-100 py-20 lg:px-10 xl:px-0">
					<div className="mx-auto flex max-w-7xl flex-col justify-between gap-5 md:flex-row">
						<div className="mb-2 pl-4 text-2xl font-semibold md:hidden">Contact</div>
						<ContactInfoSection />
						<div className="md:h-68 mx-5 md:w-0.5 md:bg-neutral-400 lg:h-36" />
						<div className="mb-2 pl-4 text-2xl font-semibold md:hidden">Site Map</div>
						<hr className="mb-2 h-[1px] w-full bg-gray-400 md:hidden" />
						<div className="grid w-2/3 grid-cols-1 px-5 md:grid-cols-2 lg:grid-cols-3">
							<LinkGroup justify="left" links={VotersAndCandidates} />
							<LinkGroup justify="right" links={ResourcesColumn1} />
							<LinkGroup justify="left" links={ResourcesColumn2} />
						</div>
					</div>
				</section>
				<Copyright links={copyright} />
			</div>
		</footer>
	);
}
