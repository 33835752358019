import { Fragment } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { Transition, Menu } from '@headlessui/react';
import type { NavBarLinks } from './data/navigation-links';

interface ComponentProps {
	item: NavBarLinks;
}

export default function DropdownComponent({ item }: ComponentProps) {
	return (
		<Menu as="div" className="relative">
			{({ open: _open }) => (
				<>
					<Menu.Button className="transition-text min-w-5 gap-x-1 rounded-md text-gray-300 duration-300 hover:bg-gray-700">
						<span className="group relative grid place-content-center text-sm font-semibold leading-6 text-white">
							<div className="flex items-center space-x-1">
								<p>{item.name}</p>
								<div className="mr-2 flex h-3 max-h-3 w-3 max-w-[28px] flex-shrink">
									<Image alt="chevron-down-icon" height="28" src="/vectors/chevron-down-icon.svg" width="28" />
								</div>
							</div>
							<hr className="border-UC-yellow-500 absolute bottom-0 w-full scale-x-0 border-b-[1.5px] transition duration-500 group-hover:scale-x-100" />
						</span>
					</Menu.Button>

					<Transition
						as={Fragment}
						enter="transition ease-out duration-200"
						enterFrom="opacity-0 translate-y-1"
						enterTo="opacity-100 translate-y-0"
						leave="transition ease-in duration-150"
						leaveFrom="opacity-100 translate-y-0"
						leaveTo="opacity-0 translate-y-1"
					>
						<Menu.Items
							className="absolute right-1/2 z-40 mt-4 flex w-screen max-w-min translate-x-1/2 transform outline-none"
							static
						>
							<div className="w-fit shrink space-y-2 rounded-xl bg-gray-800/90 p-5 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5 backdrop-blur-xl">
								{item.children?.map((child) => (
									<Menu.Item key={child.name}>
										<Link
											className="group relative block w-full text-sm font-semibold leading-6 text-white"
											href={`${child.href}`}
										>
											<div className="relative inline-block whitespace-nowrap">
												<p>{child.name}</p>
												<hr className="border-UC-yellow-500 absolute bottom-0 left-0 w-full scale-x-0 border-b-[1.5px] transition duration-500 group-hover:scale-x-100" />
											</div>
										</Link>
									</Menu.Item>
								))}
							</div>
						</Menu.Items>
					</Transition>
				</>
			)}
		</Menu>
	);
}
