const VotersAndCandidates = [
	{ name: 'Results', href: '/results' },
	{ name: 'Information For Voters', href: '/voter-information' },
	{
		name: 'Voting Locations',
		href: '/voting-locations',
	},
	{
		name: 'Information For Candidates',
		href: '/candidate-information',
	},
	{
		name: 'Candidate Records',
		href: '/candidate-records',
	},
	{
		name: 'Financial Disclosures',
		href: '/financial-disclosures',
	},
	{
		name: 'Conflict Of Interest Forms',
		href: '/conflict-of-interest-forms',
	},
];

const ResourcesColumn1 = [
	{ name: 'Forms', href: '/forms' },
	{ name: 'Maps', href: '/maps' },
	{ name: 'Notices', href: '/public-notices' },
	{ name: 'Petitions', href: '/petitions' },
];

const ResourcesColumn2 = [
	{ name: 'About Utah County Elections', href: '/about' },
	{ name: 'Calendar', href: '/calendar' },
	{ name: 'Volunteer', href: '/volunteer' },
];

export { VotersAndCandidates, ResourcesColumn1, ResourcesColumn2 };
